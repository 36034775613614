import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "space-y-8"
}
const _hoisted_2 = { class: "grid grid-cols-2 gap-6" }
const _hoisted_3 = { class: "grid grid-cols-2 gap-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CardHeader = _resolveComponent("CardHeader")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseCard = _resolveComponent("BaseCard")!
  const _directive_allow = _resolveDirective("allow")!

  return (!_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_BaseCard, { class: "space-y-8" }, {
          default: _withCtx(() => [
            _createVNode(_component_CardHeader, {
              title: _ctx.$t('platform.device.new'),
              onBack: _ctx.cancel
            }, null, 8, ["title", "onBack"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_BaseSelect, {
                modelValue: _ctx.manufacturer,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.manufacturer) = $event)),
                "data-dusk": "manufacturer-select",
                label: _ctx.$t('platform.device.manufacturer'),
                placeholder: _ctx.$t('platform.device.select-type'),
                options: _ctx.manufacturerOptions,
                error: _ctx.errors.manufacturer ? _ctx.errors.manufacturer[0] : null
              }, null, 8, ["modelValue", "label", "placeholder", "options", "error"]),
              _createVNode(_component_BaseSelect, {
                modelValue: _ctx.modelName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.modelName) = $event)),
                "data-dusk": "model-select",
                label: _ctx.$t('platform.device.model'),
                placeholder: _ctx.$t('platform.device.select-model'),
                options: _ctx.modelNameOptions,
                disabled: !_ctx.manufacturer,
                error: _ctx.errors.modelName ? _ctx.errors.modelName[0] : null
              }, null, 8, ["modelValue", "label", "placeholder", "options", "disabled", "error"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.serialNumber,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.serialNumber) = $event)),
                "data-dusk": "serial-number-input",
                label: _ctx.$t('platform.device.serial-number'),
                placeholder: _ctx.$t('platform.device.enter-serial-number'),
                error: _ctx.errors.serialNumber ? _ctx.errors.serialNumber[0] : null,
                "is-optional": ""
              }, null, 8, ["modelValue", "label", "placeholder", "error"]),
              _createVNode(_component_BaseTextInput, {
                modelValue: _ctx.customLabel,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customLabel) = $event)),
                "data-dusk": "custom-label-input",
                label: _ctx.$t('platform.device.custom-label'),
                placeholder: _ctx.$t('platform.device.enter-custom-label'),
                error: _ctx.errors.customLabel ? _ctx.errors.customLabel[0] : null,
                "is-optional": ""
              }, null, 8, ["modelValue", "label", "placeholder", "error"])
            ]),
            _createElementVNode("div", null, [
              _withDirectives((_openBlock(), _createBlock(_component_BaseButton, {
                "data-dusk": "create-device-button",
                "left-icon": "add",
                loading: _ctx.saving,
                onClick: _ctx.save
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.device.create')), 1)
                ]),
                _: 1
              }, 8, ["loading", "onClick"])), [
                [_directive_allow, 'device:create']
              ]),
              _createVNode(_component_BaseButton, {
                color: "ghost",
                class: "ml-2",
                disabled: _ctx.saving,
                onClick: _ctx.cancel
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
                ]),
                _: 1
              }, 8, ["disabled", "onClick"])
            ])
          ]),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}