
import { Vue, Options } from 'vue-class-component';
import axios, { CancelToken, CancelTokenSource } from 'axios';
import { OrganisationDeviceService } from '@/services/api';
import { DeviceModel } from '@/models';
import { IOption } from '@/lib';
import { CardHeader, BaseCard, BaseTextInput, BaseButton, BaseSelect } from '@/lib/components';
import { useSessionStore } from '@/stores/session.store';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  components: {
    CardHeader,
    BaseCard,
    BaseTextInput,
    BaseButton,
    BaseSelect
  }
})
export default class NewDevicePage extends Vue {
  sessionStore = useSessionStore();
  notificationStore = useNotificationStore();
  serialNumber = '';
  customLabel = '';

  deviceKey = '';
  manufacturer = '';
  modelName = '';

  deviceModel: DeviceModel[] = [];
  deviceId = '';
  saving = false;
  loading = true;

  errors: { [key: string]: Array<string> } = {};

  request: CancelTokenSource | null = null;

  deviceModels: Array<DeviceModel> = [];

  get deviceService(): OrganisationDeviceService {
    return new OrganisationDeviceService(this.organisationId);
  }

  get organisationId() {
    return this.$route.params.organisationId || this.sessionStore.currentOrganisationId;
  }

  get manufacturerOptions(): IOption[] {
    const uniqueManufacturers = Array.from(new Set(this.deviceModels.map((model) => model.manufacturer)));
    return uniqueManufacturers.map((manufacturerName) => ({
      value: manufacturerName,
      label: manufacturerName
    }));
  }

  get modelNameOptions(): IOption[] {
    return this.deviceModels
      .filter((model) => model.manufacturer === this.manufacturer)
      .map((model) => ({
        value: model.modelName,
        label: model.modelName
      }));
  }

  get selectedModel(): DeviceModel | undefined {
    return this.deviceModels.find(
      (model) => model.manufacturer === this.manufacturer && model.modelName === this.modelName
    );
  }

  async mounted() {
    try {
      this.request = axios.CancelToken.source();
      this.deviceModels = await this.fetchDeviceModels(this.request.token);
      this.request = null;
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.device.fetch-one-error')
        });
      }
    } finally {
      this.loading = false;
    }
  }

  unmounted() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async fetchDeviceModels(cancelToken: CancelToken) {
    return (
      await this.deviceService.getDeviceModels({
        cancelToken
      })
    ).data;
  }

  cancel() {
    this.$router.back();
  }

  async save() {
    try {
      this.saving = true;
      if (this.selectedModel) {
        this.errors = {};
        const data = {
          deviceModelId: this.selectedModel.id,
          organisationId: this.organisationId,
          serialNumber: this.serialNumber,
          customLabel: this.customLabel
        };
        const response = await this.deviceService.create(data);
        this.notificationStore.addSuccessNotification({
          title: this.$t('platform.device.create-success')
        });
        if (this.$route.name === 'domain-admin-new-device') {
          await this.$router.push({
            name: 'domain-admin-edit-device',
            params: {
              deviceId: response.data.id,
              organisationId: this.organisationId
            },
            query: {
              deviceKey: response.data.deviceKey,
              copyDeviceKey: 'true'
            }
          });
        } else {
          await this.$router.push({
            name: 'settings-edit-device',
            params: {
              deviceId: response.data.id
            },
            query: {
              deviceKey: response.data.deviceKey,
              copyDeviceKey: 'true'
            }
          });
        }
      } else {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.device.not-null-error')
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 422) {
        this.errors = error.response.data.errors;
      }
      this.notificationStore.addErrorNotification({
        title: this.$t('platform.device.create-error')
      });
    } finally {
      this.saving = false;
    }
  }
}
